import * as React from "react"
import {Navbar, Root} from "./Navbar.styles";

const BlogCard: React.FC<{}> = () => {

  return (
    <Root>
      @thetravellingprogrammer
    </Root>
  )
}

export default BlogCard;
